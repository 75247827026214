import { useState } from 'react';
import './Contact.css'
import emailjs from '@emailjs/browser';
import Popup from './Popup';
import Loading from './Loading';

const Contact = () => {
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true)

        var contact_form = `
            Name: ${document.querySelector('#contact-form-name').value}
            Phone Number: ${document.querySelector('#contact-form-phone-number').value}
            Email: ${document.querySelector('#contact-form-email').value}
            Message: ${document.querySelector('#contact-form-message').value}
        `;


        const emailParams = {
            contact_form: contact_form
        };

        // console.log(emailParams)
        emailjs.send('service_jeeerwy', 'template_ncl6fx5', emailParams, 'JZaAEMmIqM1f0XieQ')
        .then((result) => {
            console.log(result.text);
            setLoading(false)
            setSubmitted(true);
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        }, (error) => {
            console.log(error.text);
            setLoading(false)
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1500)
        });
    }
    
    return (
        <>
        <Loading loading={loading} />
        {submitted ? <Popup message='Form submitted successfully.'/> : ''}
        {error ? <Popup message={'Form submission failed. Please try again later.'}/> : ''}
        <div className="section-grid contact-section colored-background" id='contact-us'>
            <div className="section-title">Contact Us</div>

            <div className="contact-grid">
                <form className="contact-form" onSubmit={submitForm}>
                    <div  className="contact-form-row">
                        <div>
                            <div className='contact-form-label'>Name</div>
                            <input 
                                className='contact-form-input' 
                                id='contact-form-name' name='name'
                                type='text'
                                required/>
                        </div>
                        
                        <div>
                            <div className='contact-form-label'>Phone Number</div>
                            <input 
                                className='contact-form-input' 
                                id='contact-form-phone-number' name='phoneNumber'
                                type='text'
                                required/>
                        </div>
                    </div>

                    <div>
                        <div className='contact-form-label'>Email</div>
                        <input 
                            className='contact-form-input' 
                            id='contact-form-email' name='email'
                            type='text'
                            required/>
                    </div>

                    <div>
                        <div className='contact-form-label'>Message</div>
                        <textarea 
                            className='contact-form-input input-message' 
                            id='contact-form-message' name='message'
                            type='textarea'
                            rows={3}
                            required/>
                    </div>

                    <input type="submit" className='contact-form-submit' onSubmit={submitForm}/>
                </form>
                <div className="contact-details">
                    Contact Number:<br/>
                    +60 11-1071 7028<br/>
                    <br/>
                    Address:<br/>
                    7, Jalan Taming Kiri 10,<br/> 
                    Taman Taming Jaya,<br/>
                    43300 Seri Kembangan,<br/>
                    Selangor, Malaysia<br/>
                    <br/>
                    Email:<br/>
                    charlespetsuite@gmail.com
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact;