import './Features.css';
import '../App.css';

import feature1 from "../images/feature1.png";
import feature2 from "../images/feature2.png";
import feature3 from "../images/feature3.png";
import feature4 from "../images/feature4.png";
import feature5 from "../images/feature5.png";

const Features = () => {
    return (
        <div className='features-section section-grid colored-background'>
            <div className='section-title'>Why Choose Us</div>

            <div className='features-grid'>
                <div className='features-row-1'>
                    <div className='features'>
                        <img src={feature1}  className='features-image'/>
                        <div  className='features-desc features-desc-1'>
                            Roam freely and safely in the house with no restriction
                        </div>
                    </div>
                    
                    <div className='features'>
                        <img src={feature2}  className='features-image'/>
                        <div  className='features-desc features-desc-2'>
                            Play with other furkids ALL day long
                        </div>
                    </div>
                    
                    <div className='features'>
                        <img src={feature3}  className='features-image'/>
                        <div  className='features-desc features-desc-3'>
                            Sleep cageless in the security-guarded house
                        </div>
                    </div>
                </div>

                <div className='features-row-2'>
                    <div className='features'>
                        <img src={feature4}  className='features-image'/>
                        <div  className='features-desc features-desc-4'>
                            Special care is given to furkids that are in need
                        </div>
                    </div>
                    
                    <div className='features'>
                        <img src={feature5}  className='features-image'/>
                        <div  className='features-desc features-desc-5'>
                            Daily video and photos updates of your furkids
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features