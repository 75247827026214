import './Popup.css'

const Popup = ({message}) => {
    return (
        <div className="popup-grid">
            <div className="popup">
                {message}
            </div>
        </div>
    )
}

export default Popup;