import './Header.css'
import Logo from '../images/rounded_logo.png'
import Menu from '../images/menu.png'

const Header = () => {

    const scrollTo = (id) => {
        window.location.assign("/#" + id);
    }

    const toggleMobileNavGrid = () => {
        const nav = document.getElementsByClassName('mobile-nav-grid');
        const navBackground = document.getElementsByClassName('mobile-nav-grid-background');
        if (nav && nav.length) {
            nav[0].classList.toggle('active');
        }
        if (navBackground && navBackground.length) {
            navBackground[0].classList.toggle('active');
        }
    }


    const NavGrid = () => {
        return(
            <>
                <div className='app-header-nav-grid web-view'>
                    <span className='app-header-nav' onClick={() => scrollTo('services')}>
                        Services
                    </span>
                    
                    <span className='app-header-nav' onClick={() => scrollTo('location')}>
                        Location
                    </span>
                    
                    
                    <span className='app-header-nav' onClick={() => scrollTo('contact-us')}>
                        Contact Us
                    </span>

                    <div className='app-header-nav header-reserve' onClick={() => {window.location.assign('/reserve-form')}}>
                        Reserve Now
                    </div>
                </div>

                <div className='app-header-nav-grid mobile-view'>
                    <div className='app-header-nav header-reserve' onClick={() => {window.location.assign('/reserve-form')}}>
                        Reserve Now
                    </div>
                    <img src={Menu} className='mobile-nav-icon' onClick={() => {toggleMobileNavGrid()}} />

                    <div className='mobile-nav-grid-background' onClick={() => {toggleMobileNavGrid()}} >
                        <div className='mobile-nav-grid' >
                            <span className='app-header-nav' onClick={() => scrollTo('services')}>
                                Services
                            </span>
                            
                            <span className='app-header-nav' onClick={() => scrollTo('location')}>
                                Location
                            </span>
                            
                            
                            <span className='app-header-nav' onClick={() => scrollTo('contact-us')}>
                                Contact Us
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <header className='header-sticky-grid'>
            <div className='app-header-grid'>
                <img src={Logo} className='app-header-logo'  onClick={() => window.location.assign("/")}/>

                <span className='app-header-name'  onClick={() => window.location.assign("/")}>
                Charles's Pet Suite
                </span>

                <NavGrid />
            </div>
        </header>
    )
}

export default Header;