import './App.css';
import { useEffect, useState } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {db} from './firebase';

import Blog from './component/Blog';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './component/Home';
import ReserveForm from './component/ReserveForm';

function App() {
  const [blogList, setBlogList] = useState();
  const [testimonialList, setTestimonialList] = useState();
  const [imageList, setImageList] = useState([]);
  const { hash } = window.location;

  const fetchPost = async () => {
    await getDocs(collection(db, "blog"))
        .then((querySnapshot)=>{               
            var newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));
            newData.sort((a, b) => { return b.timestamp.seconds - a.timestamp.seconds })
            setBlogList(newData);
            console.log(newData);

            const storage = getStorage();
            var promises = []

            newData.forEach((blog) => {
              promises.push(new Promise((resolve, reject) => {
                const imgRef = ref(storage, blog.image);
                getDownloadURL(imgRef)
                .then((url) => {
                  var temp = {}
                  temp[blog.id] = url;
                  resolve(temp)
                })
              })
            )

            Promise.all(promises)
            .then((ret) => {
              var temp = {}
              ret.forEach((dic) => {
                temp = {...temp, ...dic}
              })
              setImageList(temp);
            })
          })
        })
        
    await getDocs(collection(db, "testimonial"))
    .then((querySnapshot)=>{               
        const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));
        setTestimonialList(newData);
        console.log(newData);
    })
  }

  useEffect(()=>{
    fetchPost();
    if (hash) {
      window.location.assign("/" + hash);
    }
  }, [])

  return (
    <div >
      <Header />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home testimonialList={testimonialList} blogList={blogList} imageList={imageList}/>} exact/>
          <Route path="/blog/:blogId" element={<Blog blogList={blogList}/>} exact/>
          <Route path="/reserve-form" element={<ReserveForm />} exact/>
        </Routes>
      </BrowserRouter>
      
      <Footer/>
    </div>
  );
}

export default App;
