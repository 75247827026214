import './OurBlog.css';
import Arrow from '../images/arrow.png'
import { useState } from 'react';

const OurBlog = ({blogList, imageList}) => {
    const [ind, setInd] = useState(0)

    const scrolling = (num) => {
        const cnt = window.innerWidth > 720 ? 3 : 2;
        var temp = Math.floor((ind + cnt - 1) / cnt) * cnt; 
        console.log(ind, cnt, temp);
        
        if (temp + (cnt) >= blogList.length && num == 1) {
            setInd(0);
        } else if (temp == 0 && num == -1) {
            setInd(Math.floor((blogList.length - 1) / cnt) * cnt);
        } else {
            setInd(temp + num * cnt)
        }
    }

    const HomeBlog = ({ind, className}) => {
        if (blogList && blogList.length > ind) {
            return (
                <div className={'home-blog-grid ' + className} onClick={() => {window.location.assign('/blog/' + blogList[ind].id)}}>
                    <img src={imageList ? imageList[blogList[ind].id] : ''} className='home-blog-image'/>
                    <div className='home-blog-title'>{blogList[ind].title}</div>
                    {blogList[ind].content.length ? <div className='home-blog-content'>{blogList[ind].content[0]}</div>: ''}
                    <div className='home-blog-read-more'>Read More</div>
                </div>
            )
        }
    }

    return (
        <div className='section-grid our-blog-section colored-background'>
            <div className='section-title'>Our Blog</div>

            <div className='our-blog-grid'>
                <img src={Arrow} className='our-blog-arrow' onClick={() => {scrolling(-1)}} />

                <div className='our-blog-list three-in-a-row'>
                    <HomeBlog ind={ind} className="our-blog-list-item-1"/>
                    <HomeBlog ind={ind+1} className="our-blog-list-item-2"/>
                    <HomeBlog ind={ind+2} className="our-blog-list-item-3"/>
                </div>
                
                <img src={Arrow} className='our-blog-arrow right-arrow' onClick={() => {scrolling(1)}} />
            </div>
        </div>
    )
}

export default OurBlog;