import { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { useParams } from "react-router-dom";
import './Blog.css'
import Loading from './Loading.js'

const Blog = ({blogList}) => {
  const { blogId } = useParams()
  const [blog, setBlog] = useState();
  const [img, setImg] = useState('');

  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const fetchImg = async () => {
    const storage = getStorage();
    const imgRef = ref(storage, blog.image);
    getDownloadURL(imgRef)
    .then((url) => {
      setImg(url);
    })
   
  }

  useEffect(()=>{
    if (!blog && blogList) {
      setBlog(blogList.filter((blog) => {return blogId == blog.id; })[0]);
    }
    if (blog) {
      fetchImg();
    }
  }, [blog, blogId, blogList])

  function BlogGrid() {
    if (blog) {
      let date = blog.timestamp.toDate();
      return (
        <>
          <img src={img} className='blog-img'/>

          <div className='blog-title-grid'>
            <div className='blog-title'>
              {blog.title}
            </div>

            <div className='blog-date'>
              {date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()}
            </div>
          </div>

          {blog.content ? blog.content.map(element => {
            return <p className='blog-para'> &emsp; {element} </p>
          }) : ''}
        </>
      )
    }
  }

  return (
    <div className='blog-grid'>
      <Loading loading={!blog}/>
      <BlogGrid/>
    </div>
  );
}

export default Blog;
