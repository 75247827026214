import './Footer.css'
import Youtube from '../images/youtube.png'
import Facebook from '../images/facebook.png'
import Instagram from '../images/instagram.png'

const Footer = () => {

    return (
        <footer className='footer-grid'>
            <span className='footer-span'>
                © Copyright 2023 Charles's Pet Suite
            </span>

            <div className='social-media-icon-grid'>
                <img src={Instagram} className='social-media-icon' onClick={() => {window.open("https://www.instagram.com/charlespetsuite/", "_blank");}}/>
                <img src={Facebook} className='social-media-icon' onClick={() => {window.open("https://www.facebook.com/profile.php?id=100086415757108", "_blank");}}/>
                <img src={Youtube} className='social-media-icon' onClick={() => {window.open("https://www.youtube.com/@charlespetsuite", "_blank");}}/>
            </div>
        </footer>
    )
}

export default Footer