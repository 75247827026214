import Loading from './Loading.js';
import Cover from './Cover.js';
import Services from './Services.js';
import Features from './Features.js';
import Testimonials from './Testimonials.js';
import Contact from './Contact.js';
import Location from './Location.js';
import OurBlog from './OurBlog.js';

const Home = ({testimonialList, blogList, imageList}) => {

    return (
        <>
            <Loading loading={!testimonialList || !blogList}/>
            <Cover/>
            <Services/>
            <Features/>
            <Testimonials testimonialList={testimonialList}/>
            <Contact/>
            <Location/>
            <OurBlog blogList={blogList} imageList={imageList}/>
        </>
    )
}

export default Home;