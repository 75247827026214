import './Services.css';
import Service1 from '../images/service1.png';
import Service2 from '../images/service2.png';

const Services = () => {
    return (
        <div className='services-section section-grid' id='services'>
            <div className='section-title'>
                What We offer
            </div>

            <div className='services-grid'>
                <div className='service-grid'>
                    <img src={Service1} alt="Pet Boarding" className='service-image'/>
                    <div className='service-name'>Pet Boarding</div>
                    <div className='service-desc text-justify'>
                        One-stop hotel-like home boarding service for your furkid, along with pet grooming and pet taxi services to give you peace of mind while you're away. 
                    </div>
                    {/* <div className='service-learn-more'>Learn More</div> */}
                </div>

                <div className='service-grid'>
                    <img src={Service2} alt="Pet Boarding" className='service-image'/>
                    <div className='service-name'>Pet Training</div>
                    <div className='service-desc text-center'>
                        Coming soon.
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Services;