import { useState } from 'react';
import DatePicker from "react-datepicker";

const PetForm = ({ind}) => {
    const [birthDate, setBirthDate] = useState(null);
    
    // const changeAllergyOpt = (ind) => {
    //     console.log(ind)
    //     if (document.querySelector(`input[name=allergy_${ind}]:checked`) 
    //         && document.querySelector(`input[name=allergy_${ind}]:checked`).value == 'Yes') {
    //         if (!document.getElementById('allergy_text_' + ind)) {
    //             console.log('addAllergyTextBox')
    //             var node = document.createElement('input')
    //             var grid = document.getElementById(`allergy-grid-${ind}`)
    //             node.setAttribute("class","reserve-form-input-text");
    //             node.setAttribute("id","allergy_text_" + ind);
    //             node.setAttribute("name","allergy_text_" + ind);
    //             node.setAttribute("type","text");
    //             if (document.querySelector(`input[name=allergy_${ind}]`).classList.contains('required')) {
    //                 grid.insertBefore(node, grid.lastChild)
    //             } else {
    //                 grid.appendChild(node)
    //             }
    //         }
    //     } else {
    //         console.log('noAllergy')
    //         if (document.getElementById('allergy_text_' + ind)) {
    //             console.log('removeAllergyTextBox')
    //             document.getElementById('allergy_text_' + ind).remove();
    //         }
    //     }
    // }

    return (
        <div>
        <div className='hr-lines'>Pet {ind+1} Details</div>
            <div className='reserve-form-row reserve-form-two-in-a-row'>
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Name</div>
                    <input 
                        className='reserve-form-input-text'
                        id={"petName_" + ind} name={"petName_" + ind}
                        type="text"/>
                </div>

                <div className='reserve-form-field'>
                    <div className='reserve-form-label'>Date of Birth</div>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={birthDate}
                        onChange={(date) =>{setBirthDate(date)}}
                        maxDate={new Date()}
                        className="reserve-form-input-text calendar-icon"
                        id={"birthDate_" + ind}
                        name={"birthDate_" + ind}
                        />
                </div>
            </div>

            <div className='reserve-form-row reserve-form-two-in-a-row'>
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Breed</div>
                    <input 
                        className='reserve-form-input-text'
                        id={"breed_" + ind} name={"breed_" + ind}
                        type="text"/>
                </div>

                <div className='reserve-form-field'>
                    <div className='reserve-form-label'>Gender</div>
                    <div className='reserve-form-three-in-a-row'>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"gender_female_" + ind} name={"gender_" + ind} value="Female"/>
                            <label for={"gender_female_" + ind}>Female</label>
                        </div>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"gender_male_" + ind} name={"gender_" + ind} value="Male"/>
                            <label for={"gender_male_" + ind}>Male</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='reserve-form-row reserve-form-two-in-a-row'>
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Meal</div>
                    <input 
                        className='reserve-form-input-text'
                        id={"meal_" + ind} name={"meal_" + ind}
                        type="text"/>
                </div>

                <div className='reserve-form-field'>
                    <div className='reserve-form-label'>Weight (kg)</div>
                    <input 
                        className='reserve-form-input-text'
                        id={"weight_" + ind} name={"weight_" + ind}
                        type="text"/>
                </div>
            </div>

            <div className='reserve-form-row'>
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Neutered/Spayed</div>
                    <div className='reserve-form-three-in-a-row'>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"NS_yes_" + ind} name={"NS_" + ind} value="Yes"/>
                            <label for={"NS_yes_" + ind}>Yes</label>
                        </div>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"NS_no" + ind} name={"NS_" + ind} value="No"/>
                            <label for={"NS_no" + ind}>No</label>
                        </div>
                    </div>
                </div>
            </div>


            <div className='reserve-form-row'>
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Potty Trained</div>
                    <div className='reserve-form-three-in-a-row'>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"PT_PT_" + ind} name={"PT_" + ind} value="Potty Tray"/>
                            <label for={"PT_PT_" + ind}>Potty Tray</label>
                        </div>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"PT_OH_" + ind} name={"PT_" + ind} value="Outside House"/>
                            <label for={"PT_OH_" + ind}>Outside House</label>
                        </div>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"PT_grass" + ind} name={"PT_" + ind} value="Grass"/>
                            <label for={"PT_grass" + ind}>Grass</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='reserve-form-row'>
                <div className='reserve-form-field' id={`allergy-grid-${ind}`}> 
                    <div className='reserve-form-label'>Allergy</div>
                    <div className='reserve-form-three-in-a-row'>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"allergy_yes" + ind} name={"allergy_" + ind} value="Yes" onClick={() => changeAllergyOpt(ind)}/>
                            <label for={"allergy_yes" + ind}>Yes</label>
                        </div>
                        <div className='reserve-form-input-radio'>
                            <input 
                                type='radio' id={"allergy_no" + ind} name={"allergy_" + ind} value="No" onClick={() => changeAllergyOpt(ind)}/>
                            <label for={"allergy_no" + ind}>No</label>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default PetForm;