import './Cover.css';
import { useEffect, useState, forwardRef } from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Cover = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    
    const changeStartDate = (date) => {
        setStartDate(date);

        let temp = new Date(date);
        temp.setDate(temp.getDate() + 1)
        if (toDate < temp) {
            setToDate(temp)
        }
    }
    
    const changeToDate = (date) => {
        setToDate(date);

        let temp = new Date(date);
        temp.setDate(temp.getDate() - 1)
        if (startDate > temp) {
            setStartDate(temp)
        }
    }

    const changeDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        // setToDate(end);

        if (end && start >= end) {
            let temp = new Date(start);
            temp.setDate(temp.getDate() + 1)
            setToDate(temp)
        } else {
            setToDate(end);
        }
    }

    useEffect(() => {
        let temp = new Date(startDate);
        temp.setDate(temp.getDate() + 1)
        setToDate(temp)
    }, [])

    const reserve = () => {
        if (startDate && toDate) {
            startDate.setHours(12)
            startDate.setMinutes(0)
            startDate.setSeconds(0)
            toDate.setHours(12)
            toDate.setMinutes(0)
            toDate.setSeconds(0)
            window.location.assign(`/reserve-form?startDate=${startDate}&endDate=${toDate}`)
        }
    }

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="cover-date-picker-grid" onClick={onClick} ref={ref}>
            <div className='from-date-picker'>
                <div className='cover-date-label'>From</div>
                <div className='cover-date-value'>{value.split(" - ")[0]}</div>
            </div>
            <div className='to-date-picker'>
                <div className='cover-date-label'>To</div>
                <div className='cover-date-value'>{value.split(" - ")[1]}</div>
            </div>
        </div>
      ));

    return (
        <>
            <div className='cover-grid'>
                <div className='cover-text-grid'>
                    <span className='cover-title'>
                        Home Pet Boarding
                    </span>
                    
                    <span  className='cover-slogan'>
                        Peace of mind for you, <br/>
                        a vacation for them
                    </span>
                </div>

                <div className='cover-reserve-grid'>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            showIcon
                            selected={startDate}
                            onChange={changeDate}
                            startDate={startDate}
                            endDate={toDate}
                            minDate={new Date()}
                            selectsRange
                            selectsDisabledDaysInRange
                            // showTimeInput
                            customInput={<DateInput />}
                            />

                    <div className='cover-reserve' onClick={reserve}>
                        Reserve
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cover;