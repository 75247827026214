import './Location.css';

const Location = () => {    
    return (
        <div className='section-grid location-section'  id='location'>
            <div className='section-title'>Location Map</div>

            <div className='location-grid'>
                <iframe
                    className="location-map"
                    loading="lazy"
                    allowFullScreen
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBHZZitvV1dSkF5YaLUMRtwx80bGtJ4C6M&q=Charles's+Pet+Suite+-+Pet+Boarding+x+Training&zoom=13">
                    </iframe>
            </div>
        </div>
    )
}

export default Location;
