import { useState } from 'react';
import './Testimonials.css'
import Arrow from '../images/arrow.png'
import FullStar from '../images/fullStar.png'
import EmptyStar from '../images/emptyStar.png'

const Testimonials = ({testimonialList}) => {
    const [ind, setInd] = useState(0)

    const getStar = (num) => {
        if (testimonialList) {
            if (testimonialList[ind].rating >= num) {
                return FullStar
            } else {
                return EmptyStar
            }
        }
    }

    const scrolling = (num) => {
        if (ind == testimonialList.length - 1 && num == 1) {
            setInd(0);
        } else if (ind == 0 && num == -1) {
            setInd(testimonialList.length - 1);
        } else {
            setInd(ind + num);
        }
    }

    return (
        <div className='section-grid testimonials-section'  id='testimonials'>
            <div className='section-title'>Testimonials</div>

            <div className='testimonials-grid'>
                <img src={Arrow} className='testimonials-arrow' onClick={() => {scrolling(-1)}}/>

                <div className='testimonials'>
                    <div className='testimonials-name'>{testimonialList ? testimonialList[ind].name : ''}</div>
                    <div className='testimonials-rating'>
                        <img src={getStar(1)} className='testimonials-star'/>
                        <img src={getStar(2)} className='testimonials-star'/>
                        <img src={getStar(3)} className='testimonials-star'/>
                        <img src={getStar(4)} className='testimonials-star'/>
                        <img src={getStar(5)} className='testimonials-star'/>
                    </div>
                    <div className='testimonials-content'>{testimonialList ? testimonialList[ind].content : ''}</div>
                </div>
                
                <img src={Arrow} className='testimonials-arrow right-arrow' onClick={() => {scrolling(1)}}/>
            </div>
        </div>
    )
}

export default Testimonials;