import './Loading.css'

const Loading = ({loading}) => {
    if (loading) {
        return (
            <div className="loading-grid">
                <div className='loading-icon'></div>
            </div>
        )
    }
}

export default Loading;